import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Hook(s) */
import { useAboutPage, useHomePage } from "~queries/hooks"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import PageArticleFooter from "~components/page/components/pageArticle/components/pageArticleFooter/pageArticleFooter"
import PageArticleLink from "~components/page/components/pageArticle/components/pageArticleLink/pageArticleLink"
import Slideshow from "~components/slideshow/slideshow"

/* Import Local Style(s) */
import "./about-article.scss"

const AboutArticle = ({ location }) => {
  const { pageTitle } = useAboutPage()
  const { _rawAboutCopy, aboutSlideshow, aboutLink } = useHomePage()
  return (
    <PageArticle title={pageTitle} className="about">
      <PageArticleSection>
        <Link to="/about">
          {aboutSlideshow.images.length > 0 ? (
            <Slideshow slideshow={aboutSlideshow} />
          ) : null}
        </Link>
        <div className="font-size--small font-weight--light-body">
          <BlockContent blocks={_rawAboutCopy} />
        </div>
      </PageArticleSection>
      <PageArticleFooter>
        <PageArticleLink to="/about" copy={aboutLink} />
      </PageArticleFooter>
    </PageArticle>
  )
}

export default AboutArticle
