import React, { useState } from "react"
import classnames from "classnames"

import slug from "slug"

import { motion, AnimatePresence } from "framer-motion"
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Global Hook(s) */
import { useVirtualPage } from "~queries/hooks"

/* Import Global Component(s) */
import Image from "~components/image/image"
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import BlockContent from "~components/blockContent/blockContent"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import AlertButton from "~components/pages/index/components/alertButton/alertButton"

/* Import Local Style(s) */
import "./virtual-fair.scss"

const VirtualFair = ({ location }) => {
  const { pageTitle, virtualFairLink, iframe, _rawDescription, useImage, virtualFairExternalLink, virtualFairImage } = useVirtualPage()
  

  // console.log(useImage, virtualFairExternalLink, virtualFairImage)

  // const pageTitle = 'Virtual Fair'

  return (
    <Page title={pageTitle} location={location}>
      <PageHeader title={pageTitle} location={location} />
      <PageArticle title={pageTitle} className="virtualfair">
        
        <PageArticleSection className="virtualfair">
          
          {useImage && virtualFairImage && 
            <figure className={'virtual_image mb--1'} >

              {virtualFairExternalLink ? <a
                href={virtualFairExternalLink}
                target="_blank"
              >
                {virtualFairImage && <Image image={virtualFairImage.asset.gatsbyImageData} />}  
              </a> : <Image image={virtualFairImage.asset.gatsbyImageData} />}
            </figure>
          }

          <div className="iframe_wrapper mb--1" dangerouslySetInnerHTML={{ __html: iframe}}></div>

          <AlertButton key={slug(virtualFairLink.title)} title={virtualFairLink.title} link={virtualFairLink.link} hex={virtualFairLink.bcolor.hex} isMailingList={virtualFairLink.isMailingList} />

          
          <div className="mt--1">
            <section className="virtualfair_description font-weight--light-body">
              <BlockContent blocks={_rawDescription} />
            </section>  
          </div>
          
          

        </PageArticleSection>
      </PageArticle>
      <Footer />
    </Page>
  )
}

export default VirtualFair
