import React, { useState } from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import Fuse from "fuse.js"

/* Import Global Hooks */
import { useHomePage, useGalleries } from "~queries/hooks"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleHeader from "~components/page/components/pageArticle/components/pageArticleHeader/pageArticleHeader"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import PageArticleFooter from "~components/page/components/pageArticle/components/pageArticleFooter/pageArticleFooter"
import PageArticleLink from "~components/page/components/pageArticle/components/pageArticleLink/pageArticleLink"
import Slideshow from "~components/slideshow/slideshow"

/* Import Page Component(s) */
import Galleries from "~components/pages/galleries/components/galleries/galleries"
import Search from "~components/pages/galleries/components/search/search"

/* Import Local Style(s) */
import "./galleries-article.scss"

const sortGalleriesAlphabetically = (galleries = []) => {
  // seperate by all #, then letter groups
  const sortedGalleryGroups = "#abcdefghijklmnopqrstuvwxyz".split("")

  // generate object with letter groups
  const sortedGalleries = sortedGalleryGroups.reduce(
    (accumulator, galleryGroup) => {
      accumulator[galleryGroup] = []
      return accumulator
    },
    {}
  )

  // add galleries to respective gallery groups
  galleries.reduce((sortedGalleries, gallery) => {
    const leadingCharacter = gallery.item.name[0].toLowerCase()
    if (isNaN(leadingCharacter)) {
      sortedGalleries[leadingCharacter].push(gallery.item)
    } else {
      sortedGalleries["#"].push(gallery.item)
    }
    return sortedGalleries
  }, sortedGalleries)

  return sortedGalleries
}

const GalleriesArticle = ({ location, displaySearch }) => {
  const { galleriesSlideshow, _rawGalleryCopy, galleriesLink } = useHomePage()
  const galleries = useGalleries("2021")
  const [searchTerm, setSearchTerm] = useState()
  const fuse = new Fuse(galleries, {
    keys: ["name"],
    threshold: 0.2,
    minMatchCharLength: 1,
  })
  const galleriesMatches = fuse.search(searchTerm ? searchTerm : "")
  const sortedGalleries = galleriesMatches
    ? sortGalleriesAlphabetically(galleriesMatches)
    : {}

  const handleChange = event => {
    setSearchTerm(event.target.value)
  }

  return (
    <PageArticle title="Galleries" className="galleries">
      <PageArticleHeader header="Galleries" />
      <PageArticleSection>
        <Link to="/galleries">
          {galleriesSlideshow.images.length > 0 ? (
            <Slideshow slideshow={galleriesSlideshow} />
          ) : null}
        </Link>
        <div className="font-size--small font-weight--light-body">
          <BlockContent blocks={_rawGalleryCopy} />
        </div>
        {displaySearch && <Search handleChange={handleChange} value={searchTerm} />}
        {sortedGalleries.length > 0 && <Galleries galleries={sortedGalleries} isGrid={false} />}
      </PageArticleSection>
      <PageArticleFooter>
        <PageArticleLink to="/galleries" copy={galleriesLink} />
      </PageArticleFooter>
    </PageArticle>
  )
}

export default GalleriesArticle
