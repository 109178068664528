import React, { useContext } from "react"


/* Import Global Hook(s) */
import { useWebsiteConfiguration } from "~queries/hooks"

import classnames from "classnames"
import LocationContext from "~context/currentLocation"

import "./location.scss"

const LocationNav = ({}) => {

	const [currentLocation, setCurrentLocation] = useContext(LocationContext)
	const { activeLocation } = useWebsiteConfiguration()


	const setMiami = () => {
		setCurrentLocation('miamiBeach')
	}

	const setHouston = () => {
		setCurrentLocation('houston')
	}

	return (<nav className="location_header font-size--small mt--2 mb--2">
		
		{activeLocation == 'houston' && <button className={classnames('location_button', {dark_gray: currentLocation != 'houston'})} onClick={setHouston}>Houston</button> }
		<button className={classnames('location_button', {dark_gray: currentLocation != 'miamiBeach'})} onClick={setMiami}>Miami Beach</button>
		{activeLocation != 'houston' && <button className={classnames('location_button', {dark_gray: currentLocation != 'houston'})} onClick={setHouston}>Houston</button> }

	</nav>)

}


export default LocationNav