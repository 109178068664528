import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"

/* Import Global Hook(s) */
import {
  useHomePage,
  useProgramPage,
  useProgramEventsSortedByDay,
} from "~queries/hooks"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleHeader from "~components/page/components/pageArticle/components/pageArticleHeader/pageArticleHeader"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import PageArticleFooter from "~components/page/components/pageArticle/components/pageArticleFooter/pageArticleFooter"
import PageArticleLink from "~components/page/components/pageArticle/components/pageArticleLink/pageArticleLink"
import Slideshow from "~components/slideshow/slideshow"

/* Import Page Components */
import ProgramDay from "~components/pages/program/day/day"

/* Import Local Style(s) */
import "./program-article.scss"

const ProgramArticle = ({ location }) => {
  const {
    daysOfProgramming,
    programSlideshow,
    displayProgramEvents,
    programLink,
    _rawProgramCopy
  } = useHomePage()
  const { pageTitle, _rawHeaderCopy } = useProgramPage()
  const programEventsSortedByDay = useProgramEventsSortedByDay()
  return (
    <PageArticle title={pageTitle} className="program">
      <PageArticleHeader header={pageTitle} />
      <PageArticleSection>
        <Link to="/program">
          {programSlideshow.images.length > 0 ? (
            <Slideshow slideshow={programSlideshow} />
          ) : null}
        </Link>
        <div className="program-header-copy font-size--small font-weight--light-body">
          <BlockContent blocks={_rawProgramCopy} />
        </div>
        {/*{displayProgramEvents && 1 > 2 && 
          programEventsSortedByDay
            .slice(0, daysOfProgramming)
            .map(({ date, events }) => {
              return <ProgramDay key={date} date={date} events={events} />
            })}*/}
      </PageArticleSection>
      <PageArticleFooter>
        <PageArticleLink to="/program" copy={programLink} />
      </PageArticleFooter>
    </PageArticle>
  )
}

export default ProgramArticle
