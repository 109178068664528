import React, { useEffect, useContext, useState } from "react"
import { useCookies } from 'react-cookie';
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import { navigate } from "gatsby"
import classnames from "classnames"
import slug from "slug"

/* Import Global Context(s) */
import TokenContext from "~context/token"
import AccountContext from "~context/account"

import ApplicationForm from '~components/pages/profile/applicationForm'

import "./account-view.scss"

const AccountView = ({ location, user }) => {
  const [editing, setEditing] = useState(false)
  const [justSaved, setSaved] = useState(false)

  const [orgName, updateOrgName] = useState(false)
  const [orgPosition, updateOrgPosition] = useState(false)
  const [hasWebsite, updateHasWebsite] = useState(false)
  const [hasClients, updateHasClients] = useState(false)
  const [hasPublications, updateHasPublications] = useState(false)
  const [hasArtistNames, updateHasArtistNames] = useState(false)
  const [hasGalleries, updateHasGalleries] = useState(false)
  const [hasTypeOfArt, updateHasTypeOfArt] = useState(false)
  const [hasLocations, updateHasLocations] = useState(false)


  const [cookies, setCookie, removeCookie] = useCookies(['sessionToken']);
  const [token, setToken] = useContext(TokenContext)
  const [account, setAccount] = useContext(AccountContext)

  const hasProfile = user.profile !== undefined ? true : false

  const queryParams = new URLSearchParams(location.search);

  const isNewAccount = queryParams.get('new')
  const newEmail = queryParams.get('email')
  const isJustSaved = queryParams.get('saved')


  const format = (string) => {
    if(string === 'digital-nft'){
      return 'Digital/NFT'
    }
    return string
  }


  const clearAccount = () => {

    setToken(false)
    setAccount(false)

    removeCookie('sessionToken', { path: '/' })

    console.log('logging out...')

  }

  
  useEffect(() => {

    if(isNewAccount){
      setEditing(true)
    }

    if(isJustSaved){
      setSaved(true)

      setTimeout(() => {
        setSaved(false)
      }, 8000)
    }
    
  }, [isNewAccount, setEditing, setSaved])


  // calculate org name
  useEffect(() => {
    const primaryRole = (hasProfile && user.profile.roles.length > 0) ? user.profile.roles.filter(r => r.primary === true).map(r => r.title)[0] : false

    console.log('updating lang based on primary role:', user)

    if(primaryRole == 'Art Advisor'){
      updateOrgName('Advising Firm Name')
      updateOrgPosition('Title')
      updateHasWebsite(true)
      updateHasClients(true)
      updateHasPublications(false)
      updateHasArtistNames(false)
      updateHasGalleries(false)
      updateHasTypeOfArt('Type of art you are interested in')
      updateHasLocations('Interested in VIP status for the following upcoming editions')

    }else if(primaryRole == 'Art Collector'){
      updateOrgName('Company')
      updateOrgPosition('Title')
      updateHasWebsite(true)
      updateHasClients(false)
      updateHasPublications(false)
      updateHasArtistNames(true)
      updateHasGalleries('Galleries you have purchased from')
      updateHasTypeOfArt('Type of art you are interested in')
      updateHasLocations('Interested in VIP status for the following upcoming editions')

    }else if(primaryRole == 'Institution'){
      updateOrgName('Institution Name')
      updateOrgPosition('Title')
      updateHasWebsite(false)
      updateHasClients(false)
      updateHasPublications(false)
      updateHasArtistNames(false)
      updateHasGalleries(false)
      updateHasTypeOfArt('Type of art you are interested in')
      updateHasLocations('Interested in VIP status for the following upcoming editions')

    }else if(primaryRole == 'Curator'){
      updateOrgName('Galleries and/or institutions you have worked with')
      updateOrgPosition(false)
      updateHasWebsite(false)
      updateHasClients(false)
      updateHasPublications(false)
      updateHasArtistNames(false)
      updateHasGalleries(false)
      updateHasTypeOfArt('Common mediums represented in your curatorial practice')
      updateHasLocations('Interested in VIP status for the following upcoming editions')

    }else if(primaryRole == 'Press'){
      updateOrgName(false)
      updateOrgPosition(false)
      updateHasWebsite(true)
      updateHasClients(false)
      updateHasPublications(true)
      updateHasArtistNames(false)
      updateHasGalleries(false)
      updateHasTypeOfArt(false)
      updateHasLocations(false)

    }else if(primaryRole == 'Art Enthusiast'){
      updateOrgName('Company')
      updateOrgPosition('Title')
      updateHasWebsite(false)
      updateHasClients(false)
      updateHasPublications(false)
      updateHasArtistNames(false)
      updateHasGalleries(false)
      updateHasTypeOfArt('Type of art you are interested in')
      updateHasLocations('Interested in VIP status for the following upcoming editions')

    }else if(primaryRole == 'Artist'){
      updateOrgName(false)
      updateOrgPosition(false)
      updateHasWebsite(false)
      updateHasClients(false)
      updateHasPublications(false)
      updateHasArtistNames(false)
      updateHasGalleries('Galleries you have shown with')
      updateHasTypeOfArt('Type of art that best describes your practice')
      updateHasLocations('Interested in VIP status for the following upcoming editions')

    }else if(primaryRole == 'Gallery'){
      updateOrgName('Gallery Name')
      updateOrgPosition('Title')
      updateHasWebsite(true)
      updateHasClients(false)
      updateHasPublications(false)
      updateHasArtistNames(false)
      updateHasGalleries(false)
      updateHasTypeOfArt('Common mediums by your represented artists')
      updateHasLocations('Interested in VIP status for the following upcoming editions')

    }else if(primaryRole == 'Art Fair'){
      updateOrgName('Art Fair Name')
      updateOrgPosition('Title')
      updateHasWebsite(false)
      updateHasClients(false)
      updateHasPublications(false)
      updateHasArtistNames(false)
      updateHasGalleries(false)
      updateHasTypeOfArt('Type of art you are interested in')
      updateHasLocations('Interested in VIP status for the following upcoming editions')
    }else if(primaryRole == 'Auction House'){
      updateOrgName('Auction House Name')
      updateOrgPosition('Title')
      updateHasWebsite(true)
      updateHasClients(false)
      updateHasPublications(false)
      updateHasArtistNames(false)
      updateHasGalleries(false)
      updateHasTypeOfArt('Type of art you are interested in')
      updateHasLocations('Interested in VIP status for the following upcoming editions')
    }


  }, [user, hasProfile, updateOrgName, updateOrgPosition, updateHasWebsite, updateHasClients, updateHasPublications, updateHasArtistNames, updateHasGalleries, updateHasTypeOfArt, updateHasLocations])


  return (
    <section className="account-view font-size--small grid mt--2">

    {justSaved && <p className="col col--full a_inline success_message mb--4">Your profile was successfully updated.</p>}

      <h1 className="col col--1_2 mcol--2_3 a_inline">Profile {!editing ? 
        <button onClick={() => { setEditing(true) }}>Edit</button>
        : <button onClick={() => { setEditing(false) }}>Cancel</button>
      }</h1>
      <aside className="col col--1_2 mcol--1_3 col--end ">
        <p className="right a_inline">
          <button onClick={clearAccount}>Logout</button>
        </p>
      </aside>
      {!editing && <section className="grid col col--full">
        <section className="col col--1_2 mcol--full mt--1">
          <h2 className="light_gray">Name</h2>
          <p>{user.firstname} {user.lastname}</p>
        </section>

        <section className="col col--1_2 mcol--full mt--1 col--end">
          <h2 className="light_gray">Email</h2>
          <p>{user.email}</p>
        </section>
        

        {hasProfile && <section className="col col--1_2 mcol--full mt--1">
                  <h2 className="light_gray">Category</h2>
                  <p><span>{user.profile.roles.filter(r => r.primary === true).map(c => <span key={slug(c.slug)}>{c.title}</span>)}</span>
                  {user.profile.roles.filter(r => !r.primary).map(c => (<span key={slug(c.slug)}><br/>{c.title}</span>))}</p>
                </section>}

        {hasProfile && <section className="col col--1_2 mcol--full col--end mt--1">
                  <h2 className="light_gray">Mobile Phone</h2>
                  {user.profile.phone && <p>{user.profile.phone}</p>}
        
                  <h2 className="light_gray mt--1">Address</h2>
                  <p>
                    {(user.profile.address) ? user.profile.address : ''}<br />
                    {user.profile['address-additional'] && <span>{user.profile['address-additional']}<br /></span>}
                    {user.profile.locality && <span>{user.profile.locality}, </span>}{user.profile.state && <span>{user.profile.state} </span>}{user.profile.zipcode && <span> {user.profile.zipcode}</span>}<br />
                    {user.profile.country ? user.profile.country.title : ''}</p>
                </section>}

        {hasProfile && orgName != false && user.profile.survey['organization-name'] && <section className="col col--1_2 mcol--full mt--1">
                  <h2 className="light_gray">{orgName}</h2>
                  <p>{user.profile.survey['organization-name']}</p>
                </section>}

        {hasProfile && orgPosition && user.profile.survey['organization-position'] && <section className="col col--1_2 mcol--full col--end mt--1">
                  <h2 className="light_gray">Title</h2>
                  <p>{user.profile.survey['organization-position']}</p>
                </section>}

        {hasProfile && hasWebsite && user.profile.survey.website && <section className="col col--full mt--1">
            <h2 className="light_gray">Website</h2>
            <p>{user.profile.survey.website}</p>
          </section>}

        {hasProfile && hasClients && user.profile.survey['clients'] !== undefined && <section className="col col--full mt--1">
            <h2 className="light_gray">Previous or Current Clients:</h2>
            <p>{user.profile.survey['clients']}</p>
          </section>}

        {hasProfile && hasPublications && user.profile.survey['publications'] !== undefined && <section className="col col--full mt--1">
            <h2 className="light_gray">Publications you contribute to:</h2>
            <p>{user.profile.survey['publications']}</p>
          </section>}

        {hasProfile && hasArtistNames && user.profile.survey['artist-names'] !== undefined && <section className="col col--full mt--1">
            <h2 className="light_gray">Artist names in your collection:</h2>
            <p>{user.profile.survey['artist-names']}</p>
          </section>}

        {hasProfile && hasGalleries && user.profile.survey['galleries'] !== undefined && <section className="col col--full mt--1">
            <h2 className="light_gray">{hasGalleries}:</h2>
            <p>{user.profile.survey['galleries']}</p>
          </section>}

        {hasProfile && hasTypeOfArt && user.profile.survey['type-of-art'] !== undefined && <section className="col col--full mt--1">
                  <h2 className="light_gray">{hasTypeOfArt}:</h2>
                  <p style={{textTransform: 'capitalize'}}>{user.profile.survey['type-of-art'] !== undefined && user.profile.survey['type-of-art'].map((a, i) => (<span key={slug(a)}>{format(a)}{i !== user.profile.survey['type-of-art'].length - 1 ? ',' : ''} </span>))}</p>
                </section>}

        {hasProfile && hasLocations && user.profile.survey['locations'] !== undefined && <section className="col col--full mt--1">
                  <h2 className="light_gray">{hasLocations}:</h2>
                  <p 
                    style={{textTransform: 'capitalize'}}
                  >
                    {user.profile.survey['locations'] == 'all' && <span>Miami Beach & Houston</span>}
                    {user.profile.survey['locations'] == 'mb' && <span>Miami Beach</span>}
                    {user.profile.survey['locations'] == 'tx' && <span>Houston</span>}
                  </p>
                </section>}

      </section>}



      {editing && <ApplicationForm 
          path={'/vips/profile'}
          user={user} 
          surveyRequired={false}
          autoEmail={newEmail}
          editing={true} />}

    </section>
  )
}

export default AccountView
