import React, { useState } from "react"
// import BlockContent from "@sanity/block-content-to-react"
import { motion, AnimatePresence } from "framer-motion"
import classnames from "classnames"
import slug from "slug"

/* Import Global Hook(s) */
import { useAboutPage } from "~queries/hooks"

/* Import Global Component(s) */
import BlockContent from "~components/blockContent/blockContent"
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import ImageGrid from "~components/imageGrid/imageGrid"
import Slideshow from "~components/slideshow/slideshow"
import BlockMatrix from "~components/global/blockMatrix"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Local Component(s) */
import AboutSection from "~components/pages/about/components/aboutSection/aboutSection"

/* Import Local Style(s) */
import "./about.scss"

const About = ({ location }) => {
  const { _rawAbout, aboutPanels, aboutBlocks, pageTitle, slideshow, partnerTypes, aboutSections } = useAboutPage()
  const [year, setYear] = useState()
  const hasYear = (year !== undefined && year !== null && year !== '') ? true : false


  return (
    <Page title={pageTitle} location={location}>
      <PageHeader title={pageTitle} location={location} />
      <PageArticle title={pageTitle} className="about">
        <PageArticleSection>
          <div className="about-description font-weight--light-body">
            <BlockContent blocks={_rawAbout} />
          </div>
          
          {slideshow.images.length > 0 ? (
            <div className="desktop-only"><ImageGrid images={slideshow.images} /></div>
          ) : null}

          {slideshow.images.length > 0 ? (
            <div className="mobile-only"><Slideshow slideshow={slideshow} /></div>
          ) : null}

          <BlockMatrix blocks={aboutBlocks} />

        </PageArticleSection>
        <AboutSection aboutPanels={aboutPanels} partnerTypes={partnerTypes} />

        <AnimatePresence exitBeforeEnter>
          {aboutSections && aboutSections.length > 0 && (
            <motion.ul
              className="years"
              key="years"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={elementTransitionAnimation.variants}
            >
              <li className="font-size--small">Past Years: </li>

              {aboutSections.map((s, i) => {
                return (
                  <li key={s.year + i} className="font-size--small">
                    <button
                      className={classnames({ active: s.year === year })}
                      onClick={() => {
                        (s.year === year) ? setYear() : setYear(s.year)
                      }}
                    >
                      {s.year}
                    </button>
                  </li>
                )
              })}
            </motion.ul>
          )}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          {hasYear && (
            <motion.div
              key="yearabout"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={elementTransitionAnimation.variants}
            >
              {aboutSections.map((s, i) => {
                return s.year === year && s._rawDescription !== undefined && s._rawDescription !== null ? (<div className="about-description font-size--small font-weight--light-body"><BlockContent blocks={s._rawDescription} /></div>) : null
              })}
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence  exitBeforeEnter>
            {hasYear && (
              <motion.div
                key="yearsaboutslideshow"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={elementTransitionAnimation.variants}
              >
                {aboutSections.map((s, i) => {
                  return s.year === year && s.slideshow !== undefined ? (
                    <div key={'slideshow'+ i}>
                      {s.slideshow.images.length > 0 ? (
                        <div className="desktop-only"><ImageGrid images={s.slideshow.images} /></div>
                      ) : null}

                      {s.slideshow.images.length > 0 ? (
                        <div className="mobile-only"><Slideshow slideshow={s.slideshow} /></div>
                      ) : null}
                    </div>
                  ) : null
                })}
              </motion.div>
            )}
        </AnimatePresence>

        <AnimatePresence  exitBeforeEnter>
          {hasYear && (
            <motion.div
                key="yearsaboutblocks"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={elementTransitionAnimation.variants}
              >
              {aboutSections.map((s, i) => {
                return s.year === year && s.aboutBlocks !== undefined ? (
                  <BlockMatrix blocks={s.aboutBlocks} />
                ) : null
              })}

            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence exitBeforeEnter>
          {hasYear && (
            <motion.div
              key="yearsaboutsection"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={elementTransitionAnimation.variants}
            >
              {aboutSections.map((s, i) => {
                return s.year === year ? (<AboutSection key={'about_'+ i} aboutPanels={s.aboutPanels} partnerTypes={s.partnerTypes} />) : null
              })}
            </motion.div>
          )}
        </AnimatePresence>
      </PageArticle>
      <Footer />
    </Page>
  )
}

export default About
