import React, { useContext } from "react"

/* Import Global Component(s) */
import Image from "~components/image/image"
import classnames from "classnames"

import "./image-grid.scss"

const ImageGrid = ({ images }) => {
	
	const imageAmount = (images === null) ? 0 : images.length

	return (
		<div className={classnames('image-grid', { 'three-up': imageAmount % 3 === 0})}>
			
				{images.filter(i => i?.asset?.gatsbyImageData).map(({ asset: { gatsbyImageData, url, id } }, index) => (
		      <div className="each-image" key={id}>
		        <div className="image-container">
		          <Image image={gatsbyImageData} />
		        </div>
		      </div>
		    ))}

		</div>
	)

}


export default ImageGrid