import React, { useState, useContext, useEffect } from "react"
import { useLockBodyScroll } from "react-use"

import slug from "slug"

/* Import Global Context(s) */
import IntroContext from "~context/intro"

/* Import Global Hook(s) */
import { useHomePage } from "~queries/hooks"

/* Import Global Component(s) */
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"

/* Import Local Article(s) */
import ProgramArticle from "./index/articles/programArticle/_programArticle"
import AboutArticle from "./index/articles/aboutArticle/_aboutArticle"
import NewsArticle from "./index/articles/newsArticle/_newsArticle"
import PodcastsArticle from "./index/articles/podcastsArticle/_podcastsArticle"
import VisitArticle from "./index/articles/visitArticle/_visitArticle"
import GalleriesArticle from "./index/articles/galleriesArticle/_galleriesArticle"
import EditSlideshow from './index/articles/editArticle/editSlideshow'

import PartnersSlideshow from './index/articles/partnersArticle/PartnersSlideshow'

/* Import Page Components */
import IntroAnimation from "~components/pages/index/components/introAnimation/introAnimation"
import FairGuideLink from "~components/pages/index/components/fairGuideLink/fairGuideLink"
import TicketsLink from "~components/pages/index/components/ticketsLink/ticketsLink"
import AlertButton from "~components/pages/index/components/alertButton/alertButton"

/* Import Local Style(s) */
import "./index/index.scss"

const Index = ({ location }) => {
  const {
    displayProgram,
    displayTickets,
    displayVisitCopy,
    displayVisitInfo,
    displaySearch,
    aboutAlert,
    galleryAlert,
    programAlert,
    visitAlert,
    newsAlert,
    podcastAlert,
    editsSlideshow,
    displayEdits,
    displayFairGuide,
    fairGuideButton,
  } = useHomePage()

  
  const [isIntroDone] = useContext(IntroContext)
  const [isLoading, setIsLoading] = useState(!isIntroDone)
  useLockBodyScroll(!isLoading && !isIntroDone)

  useEffect(() => {
    if (isLoading && !isIntroDone) {
      window.scrollTo(0, 0)
      setIsLoading(false)
    }
  }, [isLoading, isIntroDone])

  const checkA = a => { return (Array.isArray(a) && a.length > 0) ? true : false }


  return (
    <Page title="Untitled" location={location}>
      {!isIntroDone && !isLoading && <IntroAnimation />}
      <PageHeader title={"Untitled Art"} location={location} />
      
      {/* about */}
      <AboutArticle />
      {displayFairGuide && <FairGuideLink title={fairGuideButton.title} hex={fairGuideButton.bcolor.hex} /> }      
      {displayTickets && <TicketsLink />}
      { checkA(aboutAlert) && aboutAlert.map(a => (<AlertButton key={slug(a.title)} title={a.title} link={a.link} hex={a.bcolor.hex} isMailingList={a.isMailingList} />)) }

      {/* galleries */}
      <GalleriesArticle displaySearch={displaySearch} />
      { checkA(galleryAlert) && galleryAlert.map(a => (<AlertButton key={slug(a.title)} title={a.title} link={a.link} hex={a.bcolor.hex} isMailingList={a.isMailingList} />)) }

      {/* program */}
      {displayProgram && <ProgramArticle />}
      { checkA(programAlert) && programAlert.map(a => (<AlertButton key={slug(a.title)} title={a.title} link={a.link} hex={a.bcolor.hex} isMailingList={a.isMailingList} />)) }

      {/* visit */}
      <VisitArticle displayVisitCopy={displayVisitCopy} displayVisitInfo={displayVisitInfo} />
      { checkA(visitAlert) && visitAlert.map(a => (<AlertButton key={slug(a.title)} title={a.title} link={a.link} hex={a.bcolor.hex} isMailingList={a.isMailingList} />)) }

      {/* edits */}
      {displayEdits && editsSlideshow && <EditSlideshow />}

      {/* podcasts */}
      <PodcastsArticle />
      { checkA(podcastAlert) && podcastAlert.map(a => (<AlertButton key={slug(a.title)} title={a.title} link={a.link} hex={a.bcolor.hex} isMailingList={a.isMailingList} />)) }

      {/* news */}
      <NewsArticle />
      { checkA(newsAlert) && newsAlert.map(a => (<AlertButton key={slug(a.title)} title={a.title} link={a.link} hex={a.bcolor.hex} isMailingList={a.isMailingList} />)) }

      {/* partners */}
      <PartnersSlideshow />
      <Footer />
    </Page>
  )
}

export default Index
